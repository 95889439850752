import * as React from "react";

import { FormSpec, localized, multiSelectStylePicker } from "../../../form-specs";
import { LinkingSpecOptions, linkingSpec } from "../../../inputSpecs/linkingSpec";
import { getLinkFromLinkingSpec, linkingSpecDefaultValues } from "../../../utils/linking.util";

import { DynamicSearchDirectFacet } from "./SearchDirectly";
import { LocalizedContentBase } from "@maxxton/cms-api";
import { PageWidgetSpec } from "../../";
import { WidgetGroup } from "../../widget.enum";
import { getI18nLocaleObject } from "../../../i18n";
import namespaceList from "../../../i18n/namespaceList";

export interface WidgetOptions {
    styleIds?: string[];
    searchDirectType: string;
    useUnitSelectionAsUnitPreference?: boolean;
    confirmUponDropdownSelection?: boolean;
    localizedConfirmationLinks?: LocalizedConfirmationLink[];
    localizedWidgetTitles?: LocalizedWidgetTitle[];
    pageURL?: string;
    localizedPageURL?: LocalizedPageURL[];
    displayAccommodationTypeAndUnit?: boolean;
    linking: LinkingSpecOptions;
    disableResultAutoFilter: boolean;
    showAllResults: boolean;
    showUnitName: boolean;
}

export interface LocalizedConfirmationLink extends LocalizedContentBase {
    buttonText?: string;
}

export interface LocalizedWidgetTitle extends LocalizedContentBase {
    title?: string;
}
export interface LocalizedPageURL extends LocalizedContentBase {
    pageURL?: string;
}

const TARGETS = ["search-directly"];

export const widgetOptionsForm: FormSpec<WidgetOptions> = {
    id: "search-direct",
    name: getI18nLocaleObject(namespaceList.widgetSearchfacet, "searchDirect"),
    pluralName: getI18nLocaleObject(namespaceList.widgetSearchfacet, "searchDirectly"),
    properties: [
        {
            type: "statictabs",
            tabs: [
                {
                    name: getI18nLocaleObject(namespaceList.widgetButton, "general"),
                    properties: [
                        [
                            localized({
                                variable: "localizedWidgetTitles",
                                tabContent: [
                                    {
                                        variable: "title",
                                        label: getI18nLocaleObject(namespaceList.widgetDirectSearch, "directSearchWidgetTitle"),
                                        type: "text",
                                    },
                                ],
                            }),
                            {
                                variable: "searchDirectType",
                                label: getI18nLocaleObject(namespaceList.widgetSearchfacet, "searchDirectType"),
                                type: "select",
                                default: "type",
                                optionList: [
                                    {
                                        value: "type",
                                        label: getI18nLocaleObject(namespaceList.admin, "type"),
                                    },
                                    {
                                        value: "unit",
                                        label: getI18nLocaleObject(namespaceList.dynamicPlugin, "unit"),
                                    },
                                ],
                            },
                            {
                                variable: "useUnitSelectionAsUnitPreference",
                                label: getI18nLocaleObject(namespaceList.widgetDirectSearch, "useUnitSelectionAsUnitPreference"),
                                type: "checkbox",
                                visible: (options: WidgetOptions) => options.searchDirectType === "unit",
                            },
                            {
                                variable: "displayAccommodationTypeAndUnit",
                                label: getI18nLocaleObject(namespaceList.widgetDirectSearch, "displayAccommodationTypeAndUnit"),
                                type: "checkbox",
                                visible: (options: WidgetOptions) => options.searchDirectType === "unit",
                            },
                            {
                                variable: "confirmUponDropdownSelection",
                                label: getI18nLocaleObject(namespaceList.widgetDirectSearch, "confirmUponDropdownSelection"),
                                type: "checkbox",
                            },
                            {
                                variable: "showUnitName",
                                label: getI18nLocaleObject(namespaceList.widgetDirectSearch, "showUnitName"),
                                type: "checkbox",
                                visible: (options: WidgetOptions) => options.searchDirectType === "unit",
                            },

                            localized({
                                variable: "localizedConfirmationLinks",
                                tabContent: [
                                    {
                                        variable: "buttonText",
                                        label: getI18nLocaleObject(namespaceList.widgetDirectSearch, "applyDirectSearch"),
                                        type: "text",
                                    },
                                ],
                                visible: (options: WidgetOptions) => !options.confirmUponDropdownSelection,
                            }),
                            {
                                variable: "disableResultAutoFilter",
                                label: getI18nLocaleObject(namespaceList.widgetDirectSearch, "disableResultAutoFilter"),
                                type: "checkbox",
                            },
                            {
                                variable: "showAllResults",
                                label: getI18nLocaleObject(namespaceList.widgetDirectSearch, "showAllResults"),
                                type: "checkbox",
                            },
                        ],
                    ],
                },
                {
                    name: getI18nLocaleObject(namespaceList.widgetButton, "styling"),
                    properties: [[multiSelectStylePicker("styleIds", TARGETS)]],
                },
                {
                    name: getI18nLocaleObject(namespaceList.admin, "linking"),
                    properties: [
                        [
                            localized({
                                variable: "localizedPageURL",
                                tabContent: [
                                    {
                                        label: getI18nLocaleObject(namespaceList.widgetSearchfacet, "redirectURL"),
                                        variable: "pageURL",
                                        type: "text",
                                    },
                                ],
                            }),
                            linkingSpec<WidgetOptions, keyof WidgetOptions>({ variable: "linking" }),
                        ],
                    ],
                },
            ],
        },
    ],
};

export const searchDirectlyWidget: PageWidgetSpec<WidgetOptions> = {
    id: "search-direct-widget",
    type: "page",
    widgetGroup: WidgetGroup.DYNAMIC,
    name: getI18nLocaleObject(namespaceList.widgetSearchfacet, "searchDirect"),
    description: getI18nLocaleObject(namespaceList.widgetSearchfacet, "searchDirectDescription"),
    optionsForm: widgetOptionsForm,
    defaultOptions: (): WidgetOptions => ({
        styleIds: [],
        searchDirectType: "type",
        linking: linkingSpecDefaultValues,
        disableResultAutoFilter: false,
        showAllResults: false,
        showUnitName: false,
    }),
    async render(widget, context, sitemapWidgetOptions, resultOptions, dynamicContainerOptions) {
        const link = await getLinkFromLinkingSpec({ linkingSpecOptions: widget.options.linking, context });
        return <DynamicSearchDirectFacet dynamicContainerOptions={dynamicContainerOptions} options={widget.options} context={context} link={link} />;
    },
};
