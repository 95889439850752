import * as MXTS from "@maxxton/cms-mxts-api";
import * as React from "react";

import { AvailabilityUtil, UNIT_IDS_AGGREGATION } from "../../../utils/availability.util";
import { LocalizedConfirmationLink, LocalizedPageURL, LocalizedWidgetTitle, WidgetOptions } from "./";
import Select, { GroupTypeBase, OptionProps, OptionTypeBase, components } from "react-select";
import { dispatchEmptyAction, getHideWidgetClass, isClientLoggedIn } from "../../../components/utils";
import { getI18nLocaleString, wrapProps } from "../../../i18n";

import { ActionType } from "../../../redux/actions";
import { AvailabilityState } from "../../../redux/reducers/availability.types";
import { CMSProvidedProperties } from "../../../containers/cmsProvider.types";
import { ConfiguredLink } from "../../../utils/linking.util";
import { Dispatch } from "redux";
import { DomainObjectUtil } from "../../../utils/domainobject.util";
import { DynamicFilter } from "../../../redux/reducers/dynamicFilter.types";
import { DynamicWidgetBaseProps } from "../dynamicWidget.types";
import { FilterChangeAction } from "../../../redux/actions/dynamicFilterAction.types";
import { ISelect } from "../../../components/generic-form/asyncSelect.types";
import { Loader } from "../../../components/Loader";
import { MXTS as MXTSConstants } from "../../../utils/constants";
import { SORTED_RESOURCE_AGGREGATION } from "../typesearchContainer/TypesearchContainerWidget";
import { SmartLink } from "../../../components/SmartLink";
import { State } from "../../../redux";
import { Unit } from "../../mxts";
import { cancelable } from "../../../promise/cancelable";
import { chunk } from "lodash";
import { connect } from "react-redux";
import { dynamicFilterType } from "../../../redux/reducers/dynamicFilter.enum";
import { getEnv } from "../../../utils/env.util";
import { getLocalizedContent } from "../../../utils/localizedContent.util";
import namespacesList from "../../../i18n/namespaceList";

export interface SearchDirectFacetBaseProps extends DynamicWidgetBaseProps<WidgetOptions> {
    context: CMSProvidedProperties;
    link: ConfiguredLink;
}

// eslint-disable-next-line max-len
interface SearchDirectFacetProps extends SearchDirectFacetBaseProps, SearchDirectFacetStoreProps, SearchDirectFacetDispatchProps {}

interface SearchDirectFacetState {
    availableResources: MXTS.Resource[] | undefined;
    disableWidget: boolean;
    isFetching: boolean;
    selectedDirectSearchId?: number | undefined;
    opt: Array<Record<string, unknown>> | undefined;
    units: Unit[];
    resourceid?: number | undefined;
    isLoading?: boolean;
    inputValue?: string;
}

interface SearchDirectFacetDispatchProps {
    dispatchAction: Dispatch<FilterChangeAction>;
}

interface SearchDirectFacetStoreProps {
    dynamicFilter: DynamicFilter;
    availabilityState: AvailabilityState;
}

function resourceIdById(units: Unit[], id: number): number | undefined {
    const matchedUnit = units.find((unit: Unit) => unit.unitId === id);
    if (matchedUnit) {
        return matchedUnit.resourceId;
    }
    return undefined;
}

class SearchDirectFacetWidget extends React.Component<SearchDirectFacetProps, SearchDirectFacetState> {
    private cancelObtainBookableUnits: () => void = () => undefined;

    constructor(props: SearchDirectFacetProps) {
        super(props);
        this.state = {
            availableResources: [],
            disableWidget: true,
            isFetching: false,
            opt: [],
            units: [],
            isLoading: false,
        };
    }

    public async componentDidMount() {
        const { availabilityState, dispatchAction } = this.props;

        if (!availabilityState.availabilityResult) {
            dispatchEmptyAction(dispatchAction);
        }
        this.setState({ disableWidget: !isClientLoggedIn() });
    }

    public UNSAFE_componentWillReceiveProps(nextProps: Readonly<SearchDirectFacetProps>) {
        const nextResponse = nextProps.availabilityState.availabilityResult;
        const env = nextProps.availabilityState.env;
        const { options } = this.props;
        if (nextResponse && env && !options.showAllResults && (options.searchDirectType === "type" || nextProps.availabilityState.availabilityResult?.response.sortedUnits)) {
            this.updateSearchData(nextProps);
        }
    }

    Option = (props: OptionProps<OptionTypeBase, boolean, GroupTypeBase<OptionTypeBase>> & ISelect) => (
        <components.Option {...props}>
            <div className="option-item">
                <span className="item-label">{props.label}</span>
                {this.props.options.showUnitName && <span className="rounder-unit-styling">{props.label.split("-").slice(-1)}</span>}
            </div>
        </components.Option>
    );

    public render(): JSX.Element | null {
        const {
            options,
            context: { currentLocale, site },
            link,
            availabilityState,
        } = this.props;
        const { isFetching, opt, isLoading, inputValue, disableWidget } = this.state;
        const hideWidget = getHideWidgetClass(options, disableWidget);
        const localizedPageURL: LocalizedPageURL | null = getLocalizedContent({ site, currentLocale, localizedContent: options.localizedPageURL || [] });
        const pageURL = link?.url || localizedPageURL?.pageURL || options.pageURL;
        const localizedConfirmationLink: LocalizedConfirmationLink | null = getLocalizedContent({ site, currentLocale, localizedContent: options.localizedConfirmationLinks || [] });
        const localizedWidgetTitle: LocalizedWidgetTitle | null = getLocalizedContent({ site, currentLocale, localizedContent: options.localizedWidgetTitles || [] });
        // It will not show dropdown if accommodation has no sortedUnits
        const isDisabled = (availabilityState?.availabilityResult?.response?.sortedUnits?.length && availabilityState.availabilityResult.response.sortedUnits[0]?.unitId === -1) || isFetching;
        if (hideWidget === null) {
            return null;
        }
        if (isFetching) {
            return <Loader type="direct-search" />;
        }

        return (
            <div className={`${hideWidget}`}>
                <section className="typesearch type-unit-selection">
                    <div className="filter search-filter">
                        <span className="title">{localizedWidgetTitle?.title || getI18nLocaleString(namespacesList.widgetSearchfacet, "searchByName", currentLocale, site)}</span>
                        <Select
                            name={""}
                            instanceId="react-select-search-directly"
                            components={{ Option: this.Option }}
                            value={this.getSelectedOption()}
                            inputValue={inputValue}
                            onInputChange={!options.disableResultAutoFilter ? this.handleInputChange : undefined}
                            onChange={this.handleSearchDirectChange}
                            options={opt}
                            isClearable={!!options?.useUnitSelectionAsUnitPreference}
                            isDisabled={isDisabled}
                            onFocus={this.handleOnClick}
                            isLoading={isLoading}
                            noOptionsMessage={() => ""}
                            className="react-select-container search-input"
                            classNamePrefix="react-select"
                            filterOption={this.filterOptions}
                            placeholder={getI18nLocaleString(namespacesList.widgetSearchfacet, isDisabled ? "noUnitPreferenceMessage" : "search", currentLocale, site)}
                        />
                        {this.renderLinks(localizedConfirmationLink, pageURL)}
                    </div>
                </section>
            </div>
        );
    }

    // custom filter to work with both type name + unit number together with a space between them
    private filterOptions = (candidate: { label: string; value: string; data: any }, inputValue: string) => {
        const inputValueArray = inputValue.toLowerCase().split(" ");
        const options = candidate.label.toLowerCase();
        const input = inputValue.toLowerCase();
        if (input && !(inputValueArray.length > 1)) {
            return options.includes(input);
        } else if (inputValueArray.length > 1) {
            return options.includes(inputValueArray[0]) && options.includes(inputValueArray[1]);
        }
        return true;
    };

    private renderLinks = (localizedConfirmationLink: LocalizedConfirmationLink | null, pageURL: string | undefined) => {
        const {
            options,
            context: { currentLocale, site },
        } = this.props;
        const { isFetching, selectedDirectSearchId, resourceid } = this.state;
        if (options.useUnitSelectionAsUnitPreference && options.searchDirectType === "unit" && !options.confirmUponDropdownSelection) {
            return (
                <a className="search" data-role={isFetching ? "disabled" : "enabled"} onClick={this.selectUnitPreference}>
                    {localizedConfirmationLink?.buttonText || getI18nLocaleString(namespacesList.widgetSearchfacet, "goDirectlyAcco", currentLocale, site)}
                </a>
            );
        } else if (!options.confirmUponDropdownSelection) {
            return (
                <SmartLink
                    className="search"
                    href={
                        selectedDirectSearchId
                            ? options.searchDirectType === "unit"
                                ? `${pageURL}?unitid=${selectedDirectSearchId}&resourceid=${resourceid}&lan=${currentLocale.code}`
                                : `${pageURL}?resourceid=${selectedDirectSearchId}`
                            : ""
                    }
                    target="_blank"
                    rel="noreferrer"
                    data-role={isFetching ? "disabled" : "enabled"}
                >
                    {localizedConfirmationLink?.buttonText || getI18nLocaleString(namespacesList.widgetSearchfacet, "goDirectlyAcco", currentLocale, site)}
                </SmartLink>
            );
        }
    };

    private handleOnClick = async () => {
        !this.state.opt?.length && this.setState({ isLoading: true });
        const { dispatchAction, dynamicFilter, options, availabilityState, context } = this.props;
        const shouldFetchSortedUnits = !!(dynamicFilter.startdate || dynamicFilter.accokindids || dynamicFilter.amenities || dynamicFilter.resortids || dynamicFilter.regionIds?.length);
        const response = await this.getAvailabilityResult();
        const env = await getEnv({ availabilityState, context });

        if (options.searchDirectType === "unit" && !this.state.opt?.length) {
            this.getBookableUnits(response, dynamicFilter, env);
        }
        if (options.showAllResults) {
            if (response && env && options.searchDirectType === "type") {
                await this.getBookableResources(response, env);
                this.setState({ isLoading: false });
            }
        }

        // TODO: Optimize this action to improve performance
        if (options.searchDirectType === "unit" && !options.showAllResults && !availabilityState.availabilityResult?.response.sortedUnits && shouldFetchSortedUnits) {
            if (dynamicFilter.unitPreference && dynamicFilter.unitid) {
                this.setState({ selectedDirectSearchId: dynamicFilter.unitid, isLoading: false });
            }
            if (!dynamicFilter.shouldFetchSortedUnits && !dynamicFilter.unitid) {
                dynamicFilter.shouldFetchSortedUnits = true;
                dispatchAction({
                    type: ActionType.FilterChange,
                    filter: dynamicFilterType.shouldFetchSortedUnits,
                    payload: {
                        shouldFetchSortedUnits: true,
                    },
                    dispatchAction,
                });
                this.setState({ isLoading: false });
            }
        }
    };

    private updateSearchData = async (props: SearchDirectFacetProps) => {
        const response = props.availabilityState.availabilityResult;
        const env = props.availabilityState.env;
        const { availableResources } = this.state;
        let fetchedResources: MXTS.Resource[] = [];
        const {
            options,
            dynamicFilter,
            context: { mxtsApi },
        } = this.props;
        let opt: Array<Record<string, unknown>> = [];
        if (response && env) {
            const resources = response.response.resources;
            if (resources && options.searchDirectType === "type") {
                const resourceIds: number[] = [];
                // Checking if resources are in state then only fetch the ones which are not in state
                if (availableResources && availableResources.length > 0) {
                    availableResources.forEach((availableResource) => {
                        resources!.forEach((resource) => {
                            if (availableResource.resourceId !== resource.resourceId) {
                                resourceIds.push(resource.resourceId);
                            }
                        });
                    });
                } else {
                    resources!.forEach((resource) => {
                        resourceIds.push(resource.resourceId);
                    });
                }
                if (resourceIds.length > 0) {
                    fetchedResources = await mxtsApi.resources(env, { size: MXTSConstants.MAX_RESULTS, resourceIds }).then((result) => result.content);
                }

                opt = fetchedResources ? fetchedResources.map((accoType: any) => ({ value: accoType.resourceId, label: accoType.name })) : [];
            } else if (resources && options.searchDirectType === "unit" && !options.useUnitSelectionAsUnitPreference) {
                const unitPromises = resources.map(async (res: any) => {
                    const unitNightPriceInclusive = res.unitNightPriceInclusive || res.nightPriceInclusive;
                    const unitBaseNightPriceInclusive = res.unitBaseNightPriceInclusive || res.baseNightPriceInclusive;
                    if (res.units && res.units.length) {
                        const allUnitsIds = (res.units || []).map((unit: Unit) => unit.unitId);
                        return this.getResources(env, allUnitsIds, res.units, res.resourceId, unitNightPriceInclusive, unitBaseNightPriceInclusive);
                    }
                });
                const fetchedUnits = await Promise.all(unitPromises);
                let allUnits: Unit[] = [];
                if (allUnits.length) {
                    allUnits = Array.prototype.concat.apply([], fetchedUnits);
                    this.setState({ units: allUnits });
                    opt = this.transformUnitsToOptions(allUnits, options.displayAccommodationTypeAndUnit);
                } else {
                    this.getBookableUnits(response, dynamicFilter, env);
                }
            } else if (options.searchDirectType === "unit" && options.useUnitSelectionAsUnitPreference) {
                this.getBookableUnits(response, dynamicFilter, env);
            }
            if (!options.showAllResults) {
                this.setState({ opt });
            }
        }
    };

    private getBookableUnits = (response: MXTS.AvailabilityResult, dynamicFilter: DynamicFilter, env: MXTS.ApiCallOptions) => {
        const { options } = this.props;
        this.cancelObtainBookableUnits();
        const [bookableUnitsPromise, cancelObtainBookableUnits] = cancelable(this.getBookableUnitsFromAvailabilityResult(response, dynamicFilter, env));
        this.cancelObtainBookableUnits = cancelObtainBookableUnits;
        bookableUnitsPromise.then((bookableUnits: MXTS.Unit[]) => {
            this.setState({ units: bookableUnits as Unit[], opt: this.transformUnitsToOptions(bookableUnits as Unit[], options.displayAccommodationTypeAndUnit) });
        });
    };

    private getBookableResources = async (response: MXTS.AvailabilityResult, env: MXTS.ApiCallOptions) => {
        const resourceIds = response.response.resources?.map((res) => res.resourceId);
        const { mxtsApi } = this.props.context;
        let fetchedResources: MXTS.Resource[] = [];
        if (resourceIds) {
            fetchedResources = await mxtsApi.resources(env, { size: MXTSConstants.MAX_RESULTS, resourceIds }).then((result) => result.content);
        }
        const opt = fetchedResources ? fetchedResources.map((accoType: any) => ({ value: accoType.resourceId, label: accoType.name })) : [];
        this.setState({ opt });
    };

    private transformUnitsToOptions = (units: Unit[], showAccommodationType?: boolean): Array<Record<number, string>> => {
        if (showAccommodationType) {
            return units.map((unit) => {
                const accoTypeName: string[] = unit.namePath.split("/");
                return { value: unit.unitId, label: `${accoTypeName[accoTypeName.length - 1]} - ${unit.name}` };
            });
        }
        return units.map((unit: Unit) => ({ value: unit.unitId, label: unit.name }));
    };

    // eslint-disable-next-line max-len
    private getResources = async (env: MXTS.ApiCallOptions, unitIds: number[], docs: MXTS.UnitDocument[], resourceId: number, unitNightPriceInclusive: number, unitBaseNightPriceInclusive: number) => {
        const { mxtsApi } = this.props.context;
        const mxtsUnitsWithAddress: MXTS.WithAddressResult<MXTS.PagedResult<MXTS.Unit>> = await mxtsApi.unitsWithAddress(env, { size: 100, unitIds: unitIds ? unitIds : undefined });
        const firstUnit = mxtsUnitsWithAddress?.data?.content?.[0];
        const units: Unit[] = [];
        const resort = await mxtsApi.resorts(env, { size: 1, resortIds: [firstUnit?.resortId] }).then((res: MXTS.PagedResult<MXTS.Resort>) => res.content);
        const special = await mxtsApi.resources(env, { size: 1, resourceIds: [resourceId] }).then((res: MXTS.PagedResult<MXTS.Resource>) => res.content);
        if (mxtsUnitsWithAddress?.data?.content && docs) {
            mxtsUnitsWithAddress?.data?.content.forEach((unit: MXTS.Unit) => {
                docs.forEach((doc) => {
                    if (unit.unitId === doc.unitId) {
                        const unitAddress: MXTS.Address | undefined = mxtsUnitsWithAddress?.addresses?.find((address: MXTS.Address) => address.managerId === unit.accommodationAddressManagerId);
                        units.push({
                            ...unit,
                            ...doc,
                            unitNightPriceInclusive,
                            unitBaseNightPriceInclusive,
                            city: unitAddress?.city || "",
                            resortName: resort[0] ? resort[0].name : "",
                            specialName: special[0] ? special[0].name : "",
                            specialDescription: special[0] ? special[0].description : "",
                        });
                    }
                });
            });
        }
        return units;
    };

    private handleSearchDirectChange = (event?: any) => {
        const { opt, units } = this.state;
        const {
            options,
            context: { currentLocale, site },
            link,
        } = this.props;
        const value = event?.value;
        if (opt) {
            const selectedDirectSearchId = parseInt(value, 10) || undefined;
            if (options.searchDirectType === "unit") {
                const resourceid = selectedDirectSearchId !== undefined ? resourceIdById(units, selectedDirectSearchId) : undefined;
                this.setState({ resourceid });
            } else if (options.searchDirectType === "type") {
                this.setState({ resourceid: selectedDirectSearchId });
            }
            this.setState(
                {
                    selectedDirectSearchId,
                },
                () => {
                    this.handleUpdateDirectSearchId(selectedDirectSearchId);
                    if (options?.useUnitSelectionAsUnitPreference && options?.confirmUponDropdownSelection) {
                        this.selectUnitPreference();
                    }
                }
            );
            const LocalizedLinkingOptions = getLocalizedContent({ site, currentLocale, localizedContent: options?.linking?.localizedLinkButtonOptions || [] });
            const localizedPageURL: LocalizedPageURL | null = getLocalizedContent({ site, currentLocale, localizedContent: options.localizedPageURL || [] });
            const filteredResourceid = selectedDirectSearchId && options.searchDirectType === "unit" ? resourceIdById(units, selectedDirectSearchId) : selectedDirectSearchId;
            if (filteredResourceid && options.confirmUponDropdownSelection && (LocalizedLinkingOptions?.siteId || localizedPageURL?.pageURL)) {
                const pageURL = link?.url || localizedPageURL?.pageURL || options.pageURL;
                const redirectLink =
                    options.searchDirectType === "unit"
                        ? `${pageURL}?unitid=${selectedDirectSearchId}&resourceid=${filteredResourceid}&lan=${currentLocale.code}`
                        : `${pageURL}?resourceid=${selectedDirectSearchId}`;
                if (LocalizedLinkingOptions?.openInNewTab) {
                    window.open(redirectLink, "_blank");
                } else {
                    location.href = redirectLink;
                }
                this.setState({ selectedDirectSearchId: undefined });
            }
        }
    };

    private selectUnitPreference = () => {
        const { selectedDirectSearchId } = this.state;
        const action: FilterChangeAction = {
            type: ActionType.FilterChange,
            filter: dynamicFilterType.unitPreference,
            payload: {
                unitPreference: !!selectedDirectSearchId,
                unitid: selectedDirectSearchId || undefined,
            },
        };
        this.props.dispatchAction(action);
    };

    private handleInputChange = (inputValue: string, meta: { action: string }) => {
        const action: FilterChangeAction = {
            type: ActionType.FilterChange,
            filter: dynamicFilterType.directSearchInput,
            payload: {
                directSearchInput: inputValue,
            },
        };
        this.props.dispatchAction(action);
        if (["input-blur", "menu-close"].indexOf(meta.action) === -1) {
            this.setState({ inputValue });
            return inputValue;
        }
    };
    private handleUpdateDirectSearchId = (propDirectSearchId: number | undefined) => {
        const action: FilterChangeAction = {
            type: ActionType.FilterChange,
            filter: dynamicFilterType.selectedDirectSearchId,
            payload: {
                selectedDirectSearchId: propDirectSearchId,
            },
        };
        if (!this.props.options.disableResultAutoFilter) {
            this.props.dispatchAction(action);
        }
    };

    private getSelectedOption = () => {
        const { opt, selectedDirectSearchId } = this.state;
        if (typeof selectedDirectSearchId === "number") {
            if (opt?.length) {
                const selectedOption = opt.filter((option) => option.value === selectedDirectSearchId);
                return selectedOption.length ? selectedOption : null;
            }
            return null;
        }
        return selectedDirectSearchId;
    };

    private getAvailabilityResult = async () => {
        const { context, dynamicFilter, options } = this.props;

        const customAggregations: MXTS.Aggregation[] = options.searchDirectType === "unit" ? [UNIT_IDS_AGGREGATION] : [SORTED_RESOURCE_AGGREGATION];
        const unfilteredAvailability = await AvailabilityUtil.getUnfilteredAvailability(context, dynamicFilter, { customAggregations });
        const availabilityResult = unfilteredAvailability.availabilityResult;
        return availabilityResult;
    };

    private async getBookableUnitsFromAvailabilityResult(response: MXTS.AvailabilityResult, dynamicFilter: DynamicFilter, env: MXTS.ApiCallOptions): Promise<MXTS.Unit[] | undefined> {
        let unitIds: number[] | undefined;
        const { mxtsApi } = this.props.context;
        if (this.props.options.showAllResults) {
            this.getAllUnitsFromAvailabilityResult(response, dynamicFilter, env);
            return;
        } else if (dynamicFilter.unitid) {
            const dynamicFilterWithoutUnit: DynamicFilter = { ...dynamicFilter, unitid: undefined, shouldFetchSortedUnits: true };
            const availabilityResponse = await AvailabilityUtil.getAvailabilityByDynamicFilter(dynamicFilterWithoutUnit, undefined, this.props.context);
            unitIds = availabilityResponse?.availabilityResult?.response?.sortedUnits?.map((unitDoc: MXTS.UnitDocument) => unitDoc.unitId);
        } else {
            unitIds = response?.response?.unitFacet?.map((unitId) => unitId);
            if (response.response.sortedUnits) {
                unitIds = response?.response?.sortedUnits?.map((unitDoc) => unitDoc.unitId);
            }
        }
        unitIds = unitIds || [];
        return DomainObjectUtil.getUnitsByIds(mxtsApi, unitIds, env);
    }

    private async getAllUnitsFromAvailabilityResult(response: MXTS.AvailabilityResult, dynamicFilter: DynamicFilter, env: MXTS.ApiCallOptions) {
        let unitIds: number[] | undefined;
        const { mxtsApi } = this.props.context;
        if (this.props.options.showAllResults) {
            unitIds = response?.response?.unitFacet?.map((unitId) => unitId);
        } else if (dynamicFilter.unitid) {
            const dynamicFilterWithoutUnit: DynamicFilter = { ...dynamicFilter, unitid: undefined, shouldFetchSortedUnits: true };
            const availabilityResponse = await AvailabilityUtil.getAvailabilityByDynamicFilter(dynamicFilterWithoutUnit, undefined, this.props.context);
            unitIds = availabilityResponse?.availabilityResult?.response?.sortedUnits?.map((unitDoc: MXTS.UnitDocument) => unitDoc.unitId);
        } else {
            unitIds = response?.response?.unitFacet?.map((unitId) => unitId);
        }
        unitIds = unitIds || [];

        const idChunks: number[][] = chunk(unitIds, 50);
        idChunks.map((ids) =>
            mxtsApi.units(env, { size: MXTSConstants.MAX_RESULTS, unitIds: ids }).then((unitsPage: MXTS.PagedResult<Unit>) => {
                this.setState((prevState) => {
                    const { units, opt } = prevState;
                    const optData = this.transformUnitsToOptions(unitsPage?.content || [], this.props.options.displayAccommodationTypeAndUnit);
                    const chunkedUnits = [...units, ...(unitsPage?.content || [])];
                    const chunkedOpt = [...(opt || []), ...optData];
                    return {
                        ...prevState,
                        units: chunkedUnits,
                        opt: chunkedOpt,
                    };
                });
                this.setState({ isLoading: false });
            })
        );
    }
}

function mapStateToProps(state: State): SearchDirectFacetStoreProps {
    return {
        dynamicFilter: state.dynamicFilter,
        availabilityState: state.availabilityState,
    };
}

function mapDispatchToProps(dispatch: Dispatch<FilterChangeAction>): SearchDirectFacetDispatchProps {
    return { dispatchAction: dispatch };
}

const SearchDirectFacet = connect<SearchDirectFacetStoreProps, SearchDirectFacetDispatchProps>(mapStateToProps, mapDispatchToProps)(SearchDirectFacetWidget);

export const DynamicSearchDirectFacet = wrapProps<SearchDirectFacetBaseProps>(SearchDirectFacet);
